import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./screens/home/Home";
import Footer from "./components/footer/Footer";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";

const App = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/privacy-policy" && (
        <div className="containerxyz">
          <div className="top-right"></div>
        </div>
      )}
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/about" element={<About />} /> */}
      </Routes>
      <Footer />
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
