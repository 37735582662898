import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <section className="privacy-policy py-5 bg-white text-black">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <h1 className="text-center mb-4">Privacy Policy</h1>
                        <div className="privacy-content">

                            <h2>1. Introduction</h2>
                            <p>
                                Welcome to Softweirdo Solutions. Your privacy is of utmost importance to us. 
                                This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information 
                                when you use our website and services. By accessing or using our services, you agree to the collection 
                                and use of information in accordance with this policy.
                            </p>

                            <h2>2. Information We Collect</h2>
                            <p>We collect the following types of information to enhance our services:</p>
                            <ul>
                                <li><strong>Personal Information:</strong> Name, email address, phone number, and company details.</li>
                                <li><strong>Technical Data:</strong> IP address, browser type, operating system, and device information.</li>
                                <li><strong>Usage Data:</strong> Pages visited, time spent on pages, click data, and browsing behavior.</li>
                                <li><strong>Cookies & Tracking Technologies:</strong> We use cookies and similar tracking tools to improve user experience.</li>
                            </ul>

                            <h2>3. How We Use Your Information</h2>
                            <p>We use the collected data to:</p>
                            <ul>
                                <li>Provide, operate, and maintain our website and services.</li>
                                <li>Improve and personalize your user experience.</li>
                                <li>Communicate with you regarding service updates, inquiries, and promotional offers.</li>
                                <li>Enhance website security and detect fraudulent activities.</li>
                                <li>Comply with legal obligations and enforce our policies.</li>
                            </ul>

                            <h2>4. Data Retention & Protection</h2>
                            <p>
                                We retain your personal information only as long as necessary to fulfill the purposes outlined in this 
                                Privacy Policy. We implement industry-standard security measures, including encryption, 
                                secure servers, and access controls, to protect your data from unauthorized access or breaches.
                            </p>

                            <h2>5. Third-Party Services</h2>
                            <p>
                                We may engage third-party companies to assist in providing our services (e.g., analytics providers, 
                                hosting services). These third parties will only have access to personal data necessary to perform 
                                their functions and are required to maintain confidentiality.
                            </p>

                            <h2>6. Your Rights & Choices</h2>
                            <p>Depending on your location, you may have the following rights regarding your personal data:</p>
                            <ul>
                                <li><strong>Access & Correction:</strong> You can request access to and correction of your personal data.</li>
                                <li><strong>Data Deletion:</strong> You can request that we delete your personal information.</li>
                                <li><strong>Opt-Out:</strong> You can opt out of marketing communications at any time.</li>
                                <li><strong>Cookies Control:</strong> You can modify your cookie preferences through your browser settings.</li>
                            </ul>

                            <h2>7. Compliance with GDPR & CCPA</h2>
                            <p>
                                If you are a resident of the **European Union (EU)**, we process personal data in accordance with the 
                                **General Data Protection Regulation (GDPR)**.  
                                If you are a resident of **California, USA**, you may have additional rights under the **California Consumer Privacy Act (CCPA)**.
                            </p>

                            <h2>8. Changes to This Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy from time to time. Any changes will be posted on this page with a 
                                revised **"Last Updated"** date. We encourage you to review this policy periodically.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default PrivacyPolicy;
