import React from "react"
import "./heroSec.scss"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {Button} from "@mui/material"
import {buttonCircle, groupDiverse} from "../../../../comman/images"

import CountUp from "react-countup"
import {Fade} from "react-reveal"

const workData = [
  {
    number: 4,
    detail: "Years Of Experience",
  },
  {
    number: 80,
    detail: "Projects Completed",
  },
]
const workData2 = [
  {
    number: 100,
    detail: "Client Satisfaction",
  },
]

const HeroSec = () => {
  return (
    <div id="About" className="pt-60 pb-40">
      <Container>
        <Row>
          <Col lg="8" sm="12" className="pr-100 pr-sm-0">
            <Fade left>
              <div className="big-title">
                <h1>
                  Your One-Stop Destination.{" "}
                  <span>for Building a Creative & Futuristic.</span> Blockchain
                  Ecosystem!
                </h1>
              </div>
            </Fade>
          </Col>
          <Col lg="4" sm="12">
            <Fade right>
              <div className="mb-50">
                <span className="descrip">
                  Let's shape your <b>idea</b> into <b>products</b> that will
                  lead the blockchain industry in the upcoming years.
                </span>
              </div>
              <Button type="a" href="#contacts" className="btn-getstarted">
                Get Started
              </Button>
            </Fade>
          </Col>
        </Row>
      </Container>

      <Container fluid className="pl-0 pr-0 pt-100">
        <Row>
          <Col lg="9" sm="12" className="imgGroup">
            <Fade bottom>
              <img src={buttonCircle} className="img-buttonCircle" />
            </Fade>
            <img src={groupDiverse} className="img-groupDiverse" />
          </Col>
          <Col lg="3" sm="12">
            {workData?.map((el, index) => (
              <div className="workData2">
                <h1>
                  <CountUp end={el.number} />
                  <span>+</span>
                </h1>
                <Fade bottom>
                  <p>{el.detail}</p>
                </Fade>
              </div>
            ))}

            {workData2?.map((el, index) => (
              <div className="workData2">
                <h1>
                  <CountUp end={el.number} />
                  <span>%</span>
                </h1>
                <Fade bottom>
                  <p>{el.detail}</p>
                </Fade>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroSec
