import React from "react";
import { Container } from "react-bootstrap";
import "./whyus.scss";
import { s01, s02, s03, line, circle } from "../../../../comman/images";
import Fade from "react-reveal/Fade";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const serviceData = [
    {
      title:"Why Us",
      details:
        "At Softweirdo Solutions, we consider it our duty to research, come up with creative, and introduce top-notch blockchain solutions for our customers. We have developed a variety of blockchain solutions for various markets and sectors. As a result, our crew is very skilled and knowledgeable about blockchain technologies.",
      details1:  
        "We give life to innovative ideas in line with current trends. Our blockchain solutions are timely, reliable, and long-lasting. Apart from the fact that we excel in building blockchain solutions. Create your network and manage your business completely distributed and securely with our blockchain services."
    },
];

const Whyus = () => {
    return (
        <section id="Whyus" className="whyus">
            <Container>
                {serviceData?.map((el, index) => (
                    <Row className="justify-content-center" key={index}>
                        <Col lg={10}>
                            <Fade right>
                                <div className="whyus-content text-center">
                                    <h1 className="section-title">
                                        <span>{el.title}</span>
                                    </h1>
                                    <div className="whyus-details">
                                        <p>{el.details}</p>
                                        <p>{el.details1}</p>
                                    </div>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                ))}
            </Container>
        </section>
    );
};

export default Whyus;