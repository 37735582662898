import React from "react";
import { logo1 } from "../../comman/images";
import "./footer.scss";

import ScrollToTop from "react-scroll-to-top";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SocialMedia } from "../SocialMedia";
import Fade from "react-reveal/Fade";

const quickLink = [
  { title: "About", link:"#About" },
  { title: "Services", link:"#Services" },
  { title: "Why Us", link:"#Whyus" },
  { title: "Contact", link:"#contacts"},
  { title: "Privacy Policy", link:"/privacy-policy" },
];

const Footer = () => {
  return (
    <div id="footer">
      <Container>
        <Row>
          <Col lg="6" sm="12">
            <Fade left>
            <div className="first">
              <img src={logo1} className="logo mb-20" />
              <p>
              SoftWeirdo Solution is your digital partner! We provide web design, development and marketing, IT, security, and software agency.

              </p>
            </div>
            </Fade>
          </Col>
          <Col lg="3" sm="12">
            <ul className="quick-link">
              {quickLink.map((el, index) => (
                <Fade bottom>
                <li key={index}>
                  <a href={el.link}>{el.title}</a>
                </li>
                </Fade>
              ))}
            </ul>
          </Col>
          <Col lg="3" sm="12">
            <Fade right>
            <div className="newsletter">
              <h3>Let's Connect</h3>
              <a>hello@softweirdo.com</a>
            </div>
            </Fade>
            <hr />
            <Fade right>
            <div className="social">
              <h3>Social Media</h3>
              <ul className="socialinks">
                {SocialMedia?.map((el, index) => (
                  <li key={index}>
                   <a href={el.link} target="_blank"><img src={el.icon} /></a>
                  </li>
                ))}
              </ul>

              {/* {navItems.map((item) => (
              <>
                <a href={`#${item}`} className="hyperlink" key={item}>
                  {item}
                </a>
                </>
                ))} */}

            </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
