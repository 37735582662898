import React from "react";
import "./clients.scss";
import Slider from "react-slick";
import { c1, c2, c3, c4, c5, c6 } from "../../../../comman/images";

const clientsData = [c1, c2, c3, c4];
const Clients = () => {
  const settings = {
    // className: "center",
    // centerMode: true,
    // autoplay: true,
    // infinite: true,
    // // centerPadding: "60px",
    // slidesToShow: 4,
    // speed: 500,

    // initialSlide: 0,

    dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      // autoplay: true,
      // speed: 4000,
      // autoplaySpeed: 4000,
      // cssEase: "linear",


    // responsive: [
    //   {
    //     breakpoint: 1300,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 6,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 4,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       initialSlide: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
        
    //     },
    //   },
    // ],
  };

  return (
    <div className="clients">
      <h1 className="title">Let our Happy Clients do the talking!</h1>
      <Slider {...settings}>
        {clientsData?.map((el, index) => (
          <div key={index}>
            <img className="curve" src={el} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Clients;
