import { Button } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { officeContact } from "../../../../comman/images";
import { SocialMedia } from "../../../../components/SocialMedia";
import "./contact.scss";
import ContactForm from "./ContactForm";
import Fade from "react-reveal/Fade";
import { InlineWidget } from "react-calendly";
const Contact = () => {
  return (
    <div id="contacts" className="contact">
      <Container className="">
        <Row className="pb-60 pb-sm-0">
          {/* Desktop Form  */}
          <Col lg="6" sm="12" className="mb-sm-50 desktop-form">
            <Fade left>
              {/* <ContactForm /> */}
              <InlineWidget
                styles={{
                  height: "840px",
                }}
                url="https://calendly.com/hello-softweirdo/30min?background_color=1a1a1a&text_color=ffffff&primary_color=ff3700"
              />
            </Fade>
          </Col>

          <Col lg="6" sm="12" className="pl-lg-50 pb-sm-50">
            <Fade right>
              <h6 className="label">
                Your dream innovation is just a click away
              </h6>
              <h1 className="head-title">Let’s Start With Us!</h1>
              <img src={officeContact} alt="" className="img-contact" />
              <div className="email">
                <h6 className="label">Email</h6>
                <a>hello@softweirdo.com</a>
              </div>
              <div className="social">
                <h6 className="label">Social Media</h6>
                <ul className="socialins">
                  {SocialMedia?.map((el, index) => (
                    <li key={index}>
                      <a href={el.link} target="_blank">
                        {" "}
                        <img src={el.icon} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Fade>
          </Col>

          {/* Mobile Form */}
          <Col lg="6" sm="12" className="mb-sm-50 mobile-form">
            {/* <ContactForm /> */}
            <InlineWidget
              styles={{
                height: "840px",
              }}
              url="https://calendly.com/hello-softweirdo/30min?background_color=1a1a1a&text_color=ffffff&primary_color=ff3700"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
